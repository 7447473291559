/*!
 *
 * Angle - Bootstrap Admin App + AngularJS
 *
 * Version: 3.7
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Override bootstrap variables
@import "app/variables";
// Bootstrap
@import "bootstrap/bootstrap/_mixins";
// Global definition of media queries
@import "app/media-queries";
// Utilities
@import "app/utils-definitions";
// Bootstrap reset
@import "app/bootstrap-reset";
// Typography
@import "app/typo";
// Layout
@import "app/layout";
@import "app/layout-extra";
@import "app/layout-animation";
@import "app/top-navbar";
@import "app/sidebar";
@import "app/offsidebar";
// Components
@import "app/breadcrumbs";
@import "app/loading-bar";
@import "app/animate";
@import "app/button-extra";
@import "app/placeholder";
@import "app/panels";
@import "app/progress-extra";
// @import "app/radial-bar";
@import "app/user-block";
@import "app/circles";
// @import "app/timeline";
@import "app/dropdown-extra";
@import "app/row-extra";
@import "app/half-float";
// @import "app/masonry-grid";
@import "app/widget";
// @import "app/typeahead";
@import "app/table-grid";
@import "app/todo";
// @import "app/file-upload";
// @import "app/chart-flot";
// @import "app/chart-easypie";
@import "app/form-elements";
// @import "app/summernote";
// @import "app/code-editor";
// Tables
@import "app/table-extras";
// @import "app/table-angulargrid";
// Plugins
@import "app/slim-scroll";
// @import "app/calendar";
@import "app/spinner";
// @import "app/gmap";
// @import "app/vector-map";
// Mailbox
// @import "app/mailbox";
// Plans
// @import "app/plans";
// Utilities
@import "app/utils";
// Print CSS
@import "app/print";
// Settings
@import "app/settings";