/* You can add global styles to this file, and also import other style files */

//== 
@import '../node_modules/font-awesome/css/font-awesome.css';

@import '../node_modules/@fortawesome/fontawesome-free/css/all.css';

@import '../node_modules/simple-line-icons/css/simple-line-icons.css';

@import '../node_modules/loaders.css/loaders.css';

@import '../node_modules/ngx-toastr/toastr.css';

@import '../node_modules/jqcloud2/dist/jqcloud.css';

@import '../node_modules/summernote/dist/summernote.css';

@import '../node_modules/fullcalendar/dist/fullcalendar.min.css';

@import '../node_modules/codemirror/lib/codemirror.css';

@import '../node_modules/sweetalert/dist/sweetalert.css';

//== Bootstrap
@import "./app/shared/styles/bootstrap.scss";
//== Application
@import "./app/shared/styles/app.scss";

.toast-top-center {
    margin-top: 5em;
}

.barra-busca {
    padding: 0;
}

.barra-acao {
    padding: 0;
}

@media(max-width: 767px) {   
    .barra-acao {
        padding-top: 1em;
    }
}

div.amcharts-chart-div a{
    position: unset !important;
    color: lightgrey !important;
}

.ngx-chips{
    border: 1px solid #dde6e9 !important;
    border-bottom: 1px solid #dde6e9;
    border-radius: 4px;
}

.ng-trigger-fade{ 
    top: 48px !important;
    left: 15px !important;
    position: absolute !important;
}